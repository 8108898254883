const rules = {
  RETAILER: {
    static: [
      "dashboard:appdashboard",
      "dashboard:marketplace",
      "dashboard:myorders",
      "dashboard:tryon",
      "dashboard:stores&users",
      "dashboard:virtualmirror",
      "dashboard:reports",
      "dashboard:settings",
      "dashboard:logout",
      "dashboard:inventories",
      "dashboard:appstore",
      "dashboard:apporders",
      "dashboard:sellers",
      "dashboard:addinventory",
    ],
  },
  WHOLESALER: {
    static: [
      "dashboard:appdashboard",
      "dashboard:marketplace",

      "dashboard:tryon",
      "dashboard:settings",
      "dashboard:retailers",
      "dashboard:Myuser",
      "dashboard:virtualmirror",

      "dashboard:analytics",
      "dashboard:logout",
      "dashboard:incomingorders",
      "dashboard:inventories",
      "dashboard:appstore",
      "dashboard:addinventory",
      "dashboard:masters",
    ],
  },
  BRANDS: {
    static: [
      "dashboard:marketplace",
      "dashboard:myorders",
      "dashboard:tryon",
      "dashboard:virtualmirror",
      "dashboard:customers",
      "dashboard:analytics",
      "dashboard:settings",
      "dashboard:logout",
      "dashboard:incomingorders",
      "dashboard:inventories",
      "dashboard:ininventory",
    ],
  },
  EXECUTIVE_BRAND: {
    static: [
      "dashboard:dashboard",
      "dashboard:settings",
      "dashboard:logout",
      "dashboard:virtualmirror",
      "dashboard:inventories",
      "dashboard:integration",
      "dashboard:ininventory",
    ],
  },
};
export { rules };
