import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./pricing.css";
import tick from "./img/tick.png";
import { SettingsService } from "../settings/settings.service";
import { toast } from "../shared/toast";
import { PricingService } from "./pricing.service";

export class Pricing extends Component {
  static propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    signup: PropTypes.bool,
    changeStep: PropTypes.func,
  };
  static defaultProps = {
    signup: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      planId: "",
    };
  }

  componentDidMount() {
    this.getRazorpaySubscriptions();
    this.getRazorpayPlans();
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    document.body.appendChild(script);
  }

  async getAccountSettings() {
    await SettingsService.getAccountData().then((result, err) => {
      if (result) {
        console.log(result.data.data[0].commonSubscriptionId);
      } else {
        toast.error("Please contact support ");
      }
    });
  }

  getRazorpayPlans() {
    PricingService.getPlans().then((response) => {
      if (response && response.message == "success") {
        this.setState({ razorypayPlans: response.data.items }, () =>
          console.log(this.state.razorypayPlans)
        );
      } else {
        toast.error("Please contact support ");
      }
    });
  }

  async openCheckout(planId, planName, planDescription) {
    let chargeDate = new Date();

    let subscriptionCheck = await SettingsService.getAccountData();
    if (subscriptionCheck) {
      let subscriptionId = subscriptionCheck.data.data[0].commonSubscriptionId;

      if (subscriptionId == "") {
        chargeDate = Math.floor(
          chargeDate.setDate(chargeDate.getDate() + 30) / 1000
        );
        console.log(chargeDate);
        var options = {
          plan_id: planId,
          total_count: 100,
          quantity: 1,
          customer_notify: 1,
          // start_at: chargeDate,

          notes: {
            for: "oicappsv4",
            access: "full",
          },
        };

        PricingService.createSubscription(options).then((response, err) => {
          if (response && response.message == "success") {
            let subData = response.data;
            let options = {
              key: "rzp_test_8GXC8xOIwcWxdN",
              subscription_id: subData.id,
              name: planName,
              description: planDescription,
              image: "/fav.png",
              handler: (response) => {
                SettingsService.setBuisnessSettings({
                  commonSubscriptionId: response.razorpay_subscription_id,
                }).then((result) => {
                  if (result && result.data && result.data.data) {
                    toast.success("SUBSCRIPTION DONE");
                    // localStorage.removeItem("authTokens");
                    // localStorage.removeItem("accounts");
                    // StoreProvider.destroy("Tokens");
                    this.props.history.push(`/main/inventory/myinventory`);
                  } else {
                    toast.error("Please contact support ");
                  }
                });
              },
              // prefill: {
              //   name: "Maxi",
              //   email: "maxi@gmail.com",
              //   contact: "8438106061",
              // },
              // notes: {
              //   note_key_1: "Tea. Earl Grey. Hot",
              //   note_key_2: "Make it so.",
              // },
              theme: {
                color: "#F37254",
              },
            };

            let rzp = new Razorpay(options);
            rzp.open();
          } else {
            toast.error("Please contact support ");
          }
        });
      } else {
        chargeDate = Math.floor(
          chargeDate.setDate(chargeDate.getDate()) / 1000
        );
        console.log(chargeDate);
        var options = {
          plan_id: planId,
          // start_at: chargeDate,
          subscription_id: subscriptionId,
        };

        PricingService.updateSubscription(options).then((response) => {
          if (response) {
            if (
              response &&
              response.message == "success" &&
              response.data.plan_id == planId
            ) {
              toast.success("Plan updated successfully");
            } else {
              toast.error("Please contact support ");
            }
          }
        });
      }
    } else {
      toast.error("Please contact support ");
    }
  }

  getRazorpaySubscriptions() {
    PricingService.getParticularSubscription().then((response, err) => {
      console.log(response);
      if (response && response.message == "success") {
        this.setState({ planId: response.data.plan_id }, () => {
          console.log(this.state.planId);
        });
      } else if (response.message == "No Subscriptions") {
      } else {
        toast.error("Please contact support ");
      }
    });
  }

  render() {
    return (
      <div className={styles.wrapper}>
        {/*Pricing Starts*/}
        <div className={`${styles.myContainer} ${styles.pricing}`}>
          <div className="container-fluid">
            <div className={`row ${styles.pricingCont}`}>
              <div
                className={`col-xl-12 ${styles.contentContainer} text-center`}
              >
                <h2 style={{textAlign:"center"}}>CHOOSE YOUR PLAN</h2>
                <p>Find The Perfect Plan For You!</p>
              </div>
              <div style={{gap:"50px"}}
                className={`row ${styles.pricingCont}`}
              >

              {this.state.razorypayPlans && this.state.razorypayPlans.length>0 ?
                this.state.razorypayPlans.map((items,index)=>{
                  console.log(items);
                  if(items.notes && items.notes.for=="oicappsv4")
                  return(
                    <div className={`col-xl-3 ${styles.pricingCard}`}>
                  <h4>{items.item.name}</h4>
                  <div>
                    <p className={styles.dollar}>{items.item.amount}</p>
                    <p className={styles.dollarType}>
                      {items.item.currency} <br />
                      /MO
                    </p>
                  </div>
                  <ul>
                    <li style={{ color: "#ff9810" }}>
                      <img src={tick} width="10" height="10" />
                      <p> Features In essential +</p>
                    </li>
                    <li>
                      <img src={tick} width="10" height="10" />
                      <p> seo Enabled</p>
                    </li>
                    <li>
                      <img src={tick} width="10" height="10" />
                      <p> Site Analytics</p>
                    </li>
                  </ul>
                  {this.state.razorypayPlans && (
                    <button
                      type="button"
                      onClick={() =>
                        this.openCheckout(
                          items.id,
                          items.item.name,
                          items.item.description
                        )
                      }
                      className={`${styles.btn1} ${styles.btnborder}`}
                    >
                      {this.state.planId == this.state.razorypayPlans[0].id
                        ? "Subscribed"
                        : "Choose " + items.item.name}
                    </button>
                  )}
                </div>
                  );
                })
                : null}

              </div>
            </div>
          </div>
        </div>
        {/*Pricing Ends*/}
      </div>
    );
  }
}
