import React, { Component } from "react";
import styles from "./styles/style.css";
import PropTypes from "prop-types";

class CircularNav extends Component {
  static propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {};
    this.changePage = this.changePage.bind(this);
  }
  changePage(page) {
    localStorage.setItem("activeSideMenu", page.split('/')[1]);
    this.props.history.push(`/main/${page}`);
  }

  render() {
    return (
      <div className={styles.circularContainer}>
        {/* logo */}
        <div style={{ position: "relative", marginTop: "1rem" }}>
          <img
            src={require("./assets/OICLogoblack.png")}
            className={styles.oicLogo}
          />
          <ul className={styles.circleContainer}>
            <li onClick={()=>{this.changePage("services/feedback")}}>
              <img
                src={require("./assets/menu/Services.png")}
                className={styles.circle}
              />
              <div className={styles.menuContainer}>
                <img
                  src={require("./assets/Services_icon.png")}
                  className={styles.menuIcon}
                />
                <p>Services</p>
              </div>
            </li>
           
            
            <li onClick={()=>{this.changePage("selfietryon/tryon")}}>
              <img 
                src={require("./assets/menu/Marketing.png")}
                className={styles.circle}
              />
              <div className={styles.menuContainer}>
                <img
                  src={require("./assets/VirtualMirror_icon.png")}
                  className={styles.menuIcon}
                />
                <p>Tryon</p>
              </div>
            </li>{" "}

            <li onClick={()=>{this.changePage("masters/brands")}}>
              <img
                src={require("./assets/menu/OnlineStore.png")}
                className={styles.circle}
              />
              <div className={styles.menuContainer}>
                <img
                  src={require("./assets/Masters_icon.png")}
                  className={styles.menuIcon}
                />
                <p>Masters</p>
              </div>
            </li>{" "}

            <li onClick={()=>{this.changePage("reports/sales")}}>
              <img
                src={require("./assets/menu/Reports.png")}
                className={styles.circle}
              />
              <div className={styles.menuContainer}>
                <img
                  src={require("./assets/Reports_icon.png")}
                  className={styles.menuIcon}
                />
                <p>Reports</p>
              </div>
            </li>{" "}

            <li onClick={()=>{this.changePage("lens/singlevision")}}>
              <img
                src={require("./assets/menu/Masters.png")}
                className={styles.circle}
              />
              <div className={styles.menuContainer}>
                <img
                  src={require("./assets/lens.png")}
                  className={styles.menuIcon}
                />
                <p>Lens Simulation</p>
              </div>
            </li>{" "}
            <li onClick={()=>{this.changePage("purchases/mycart")}}>
              <img
                src={require("./assets/menu/VirtualMirror.png")}
                className={styles.circle}
              />
              <div className={styles.menuContainer}>
                <img
                  src={require("./assets/bag.png")}
                  className={styles.menuIcon}
                />
                <p>Purchases</p>
              </div>
            </li>{" "}
            <li onClick={()=>{this.changePage("sales/orders")}}>
              <img
                src={require("./assets/menu/Inventory.png")}
                className={styles.circle}
              />
              <div className={styles.menuContainer}>
                <img
                  src={require("./assets/Sales_icon.png")}
                  className={styles.menuIcon}
                />
                <p>Sales</p>
              </div>
            </li>
            <li onClick={()=>{this.changePage("inventory/myinventory")}}>
              <img
                src={require("./assets/menu/Sales.png")}
                className={styles.circle}
              />
              <div className={styles.menuContainer}>
                <img
                  src={require("./assets/Inventory_icon.png")}
                  className={styles.menuIcon}
                />
                <p>Inventory</p>
              </div>
            </li>{" "}
          </ul>
        </div>
        {/* base */}
        <div className={styles.baseContainer}>
          <div></div>
          <p>Version 2.1.3</p>
          <div>
            <a target="_blank" href="https://www.oicapps.com/about-us/">About</a>
            <a target="_blank" href="https://www.oicapps.com/contact-us/">Contact</a>
            <a target="_blank" href="https://www.oicapps.com/privacy-policy">Privacy Policy</a>
          </div>
        </div>
      </div>
    );
  }
}

export default CircularNav;
