import DataService from '../shared/service';

export const SettingsService = {
  getUserSettings: () => DataService.get(`v1/accounts/auth/accounts/meta/users`),
  getBuisnessSettings: () => DataService.get(`v1/accounts/auth/accounts/meta/accounts`),
  setBuisnessSettings: (data) => DataService.post(`v1/accounts/account`, { data: data }),
  getAccountData: () => DataService.get('v1/accounts/account'),
  setUserSettings: (userId, data) => DataService.patch(`v1/accounts/users/${userId}`, { data: data }),
  createStore: (data) => DataService.post(`v1/accounts/stores`, { data: data }),
  createInvoiceTask: (data) => DataService.post('v1/accounts/tasks/taskengine/cg4', { data: data }),
  getuserData: (userId) => DataService.get(`v1/accounts/users/${userId}`)
};
