import DataService from "../shared/service";

export const PricingService = {
  createSubscription: (data) =>
    DataService.post(`v1/accounts/auth/razorpay/createsubscription`, {
      data: data,
    }),
  updateSubscription: (data) =>
    DataService.get(`v1/accounts/auth/razorpay/updatesubscription`, {
      query: data,
    }),

  getPlans: () => DataService.get(`v1/accounts/auth/razorpay/getplans`),
  getParticularSubscription: () =>
    DataService.get(`v1/accounts/auth/razorpay/particularsubscriptionv2`),
  generatePaymentLink: (data) =>
    DataService.post(`v1/payments/credits/generatepaymentlink`, {data: data}),
};
